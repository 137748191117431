import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageHeader from '../components/PageHeader'
import NextSteps from '../components/NextSteps'
import PanelList from '../components/PanelList'

const ServicesPage = ({ data: { prismicServicesPage }, className }) => {
  const { data } = prismicServicesPage

  return (
    <Layout className={className}>
      <Helmet
        title={`Charthouse | ${data.title.text}`}
        meta={[
          { name: 'description', content: data.introduction.text},
          { property: 'og:title', content: `Charthouse | ${data.title.text}` },
          { property: 'og:description', content: data.introduction.text },
          { name: 'twitter:title', content: `Chartouse | ${data.title.text}` },
          { name: 'twitter:description', content: data.introduction.text },
        ]}
      />
      <PageHeader 
        heading={data.title.text}
        introduction={data.introduction.html}
      />

      <Container>
        <PanelList
          list={data.services}
        />
      </Container>

      {data.next_steps && data.next_steps.length > 0 && 
        <NextSteps
          steps={data.next_steps}
        />
      }
    </Layout>
  )
}

export default ServicesPage

export const pageQuery = graphql`
  query ServicesPageQuery {
    prismicServicesPage {
      data {
        title {
          text
        }
        introduction {
          html
        }
        services {
          heading {
            text
          }
          description {
            html
          }
          image {
            url
          }
          link_label {
            text
          }
          link {
            url
          }
        }
        next_steps {
          heading {
            text
          }
          button_label {
            text
          }
          link {
            url
          }
        }
      }
    }
  }
`